<template>
  <div class="container">
    <h3 class="text-center my-3">Смена почты</h3>
    <div class="search-field my-3 d-flex flex-column">
      <span class="p-input-icon-left col-12 col-md-5">
        <i class="pi pi-search" />
        <InputText v-model="searchingName" placeholder="Логин/Баркод/Имя/Фамилия"
                   aria-describedby="username-help" class="col-12"/>
      </span>
      <small class="helper-text mt-1">Введите более 2 символов</small>
    </div>
    <div class="row row-cols-2" v-if="users.length > 0">
      <div class="col p-2" v-for="(user, index) in users" :key="index" @click="openUserEmailChangeModal(user.user_id)"
           style="cursor: pointer">
        <Card>
          <template #content>
            <h4 style="font-weight: 500; font-size: 22px">{{user.lastname}} {{user.firstname}} {{user.middlename}}<br>
              {{user.lastname_en}} {{user.firstname_en}}
            </h4>
            <p style="font-size: 18px">Логин: {{user.username}}</p>
            <Button v-if="user.active_directory == 0" :disabled="user.check_ad != 1" label="Активировать аккаунт"
                    icon="pi pi-check" @click="activeAccount($event, user.user_id)"/>
          </template>
        </Card>
      </div>
    </div>

    <Dialog v-model:visible="emailsChangeModalVisible" modal header="Смена почты студента" :style="{ width: '50wv' }">
      <h5 style="font-weight: 500; font-size: 18px">
        <div class="p-inputgroup flex-1">
          <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
          </span>
          <InputText size="large" :placeholder="
            users.find(i => i.user_id == selectedUserId).lastname + ' ' +
            users.find(i => i.user_id == selectedUserId).firstname + ' ' +
            users.find(i => i.user_id == selectedUserId).middlename
          " disabled/>
        </div>
        <div class="p-inputgroup flex-1 my-2">
          <span class="p-inputgroup-addon">
              <i class="pi pi-hashtag"></i>
          </span>
          <InputText :placeholder="users.find(i => i.user_id == selectedUserId).username" disabled/>
        </div>
        <div class="p-inputgroup flex-1 my-2">
          <span class="p-inputgroup-addon">
              @
          </span>
          <InputText v-model="newEmail" placeholder="Почта"/>
        </div>
        <div v-if="newEmail.length > 5">
          <small v-if="!isValidEmail(newEmail)" class="text-danger">Введите правильно почту</small>
        </div>
      </h5>
      <template #footer>
        <Button class="p-button-danger" label="Отменить" icon="pi pi-times" @click="closeUserEmailChangeModal"/>
        <Button label="Изменить почту" icon="pi pi-check" @click="changeUserEmail()"/>
      </template>
    </Dialog>
  </div>
  <ConfirmPopup></ConfirmPopup>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {isValidEmail} from "@/utils/helpers/emailValidate.helpers";

export default {
  name: "EmailChange",
  data(){
    return{
      searchingName: '',
      selectedUserId: null,
      selectedEmailChangeUsers: [],
      emailsChangeModalVisible: false,
      newEmail: ''
    }
  },
  watch: {
    async searchingName(newVal, oldVal){
      if (newVal !== oldVal && this.searchingName.length > 2){
        await this.GET_SEARCH_TEACHES(this.searchingName)
      }
    },
  },
  computed: {
    ...mapState('officeRegistrationEmailChange', ['users']),
  },
  methods: {
    isValidEmail,
    ...mapActions('officeRegistrationEmailChange', ['GET_SEARCH_TEACHES', 'PUT_CHANGE_EMAIL', 'POST_ACTIVE_ACCOUNT']),
    ...mapMutations('officeRegistrationEmailChange', ['SET_USERS']),
    openUserEmailChangeModal(user_id){
      this.emailsChangeModalVisible = true
      this.selectedUserId = user_id
    },
    closeUserEmailChangeModal(){
      this.emailsChangeModalVisible = false
      this.selectedUserId = null
      this.newEmail = ''
      this.SET_USERS([])
      this.searchingName = ''
    },
    async changeUserEmail() {
      let reqRes = await this.PUT_CHANGE_EMAIL({user_id: this.selectedUserId, email: this.newEmail})
      if (reqRes) {
        this.$message({title: 'Email успешно изменен'})
      }
      this.closeUserEmailChangeModal()
    },
    activeAccount(event, user_id) {
      event.stopPropagation();
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
          this.POST_ACTIVE_ACCOUNT({user_id, active_directory: 1})
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
      });
    },
  },
}
</script>

<style scoped>
.helper-text{
  color: #495057;
  font-weight: 400;
  font-size: 12px;
}
</style>